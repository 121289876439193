<template>
  <div>
    <footer id="footer" class="footer-wrapper dark">
      <div class="container">
        <div class="row">
          <div class="col-md-5">
            <div id="footer-widget-1" class="footer-widget">
              <img
                src="https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117694/web/logo-standard.png"
                class="footer-logo"
              />
              <p>
                M-Changa is Africa's largest Online Fundraising Platform. We
                provide the most Secure, Transparent & Convenient way to raise
                funds for Medical Bills, Construction, Emergencies, Funerals,
                Weddings, Birthdays, Churches, School Fees, Group Projects,
                Sports Clubs and more!
              </p>
              <div class="footer-social">
                <a v-for="item in socials" :key="item.text" :href="item.to">
                  <img :src="item.src" />
                </a>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div id="footer-widget-2" class="footer-widget">
              <h3 class="widget-title text-white">Menu</h3>
              <ul class="footer-menu">
                <li><router-link to="/about">About</router-link></li>
                <li><router-link to="/faq">Questions</router-link></li>
                <li><router-link to="/resources">Resources</router-link></li>
                <li>
                  <router-link to="/mobile-fundraiser"
                    >USSD Fundraising</router-link
                  >
                </li>
                <li>
                  <router-link to="/online-fundraiser"
                    >Online Fundraising</router-link
                  >
                </li>
                <li><router-link to="/champions">Champions</router-link></li>
                <li>
                  <router-link to="/communities">Communities</router-link>
                </li>
                <li>
                  <router-link to="/mchanga-communities"
                    >M-Changa Communities</router-link
                  >
                </li>
                <li>
                  <router-link to="/fees"
                    >M-Changa Fees</router-link
                  >
                </li>
              </ul>
            </div>
          </div>
          <!-- <div class="col-md-3">
            <div id="footer-widget-3" class="footer-widget">
              <h3 class="widget-title text-white">Recent Post</h3>
              <div class="recent-items-wrap">
                <div class="recent-post-item">
                  <a
                    class="twitter-timeline"
                    :data-width="tweetWidth"
                    data-height="300"
                    data-theme="dark"
                    href="https://twitter.com/m_changa?ref_src=twsrc%5Etfw"
                    >Tweets by m_changa</a
                  >
                </div>
              </div>
            </div>
          </div>-->
          <div class="col-md-4">
            <div id="footer-widget-4" class="footer-widget">
              <h3 class="widget-title text-white">Contact</h3>
              <p>
                Westcom Building, Mahiga Mairu Avenue, off Waiyaki Way, 3rd
                Floor, Block B
              </p>
              <div class="contact-item">
                <img
                  src="https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117694/web/ic-phone-footer.png"
                />
                <div class="contact-text">
                  <p>{{ this.supportPhone }}</p>
                </div>
              </div>
              <div class="contact-item">
                <img
                  src="https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117694/web/ic-mail-footer.png"
                />
                <div class="contact-text">
                  <p>{{ this.supportEmail }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="bottom-footer-wrapper">
              <div class="footer-copyright">
                © {{ currentYear }} M-Changa. All Rights Reserved
              </div>
              <div class="footer-legal">
                <router-link to="/privacy-policy">Privacy Policy</router-link>
                <router-link to="/terms-of-use">Terms of use</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
export default {
  props: {
    bottomClass: String,
  },
  data() {
    return {
      socials: [
        {
          src: "https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117694/web/facebook.png",
          text: "Facebook",
          to: this.facebookLink,
        },
        {
          src: "https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117694/web/twitter.png",
          text: "Twitter",
          to: this.twitterLink,
        },
        {
          src: "https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117694/web/linkedin.png",
          text: "LinkedIn",
          to: this.linkedinLink,
        },
        {
          src: "https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117694/web/youtube.png",
          text: "Youtube",
          to: this.youtubeLink,
        },
        {
          src: "https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117694/web/instagram.png",
          text: "Instagram",
          to: this.instagramLink,
        },
      ],
      currentYear: new Date().getFullYear(),
      c_err: false,
      c_success: false,
    };
  },
  mounted() {
    //GA script
    (function (i, s, o, g, r, a, m) {
      i["GoogleAnalyticsObject"] = r;
      (i[r] =
        i[r] ||
        function () {
          (i[r].q = i[r].q || []).push(arguments);
        }),
        (i[r].l = 1 * new Date());
      (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
      a.async = 1;
      a.src = g;
      m.parentNode.insertBefore(a, m);
    })(
      window,
      document,
      "script",
      "https://www.google-analytics.com/analytics.js",
      "ga"
    );
    ga("create", "UA-210321564-1", "auto");
    ga("send", "pageview");
    //end GA script

    /* eslint-disable */
    /*(function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0],
        t = window.twttr || {};
      if (d.getElementById(id)) return t.widgets.load();
      js = d.createElement(s);
      js.id = id;
      js.src = "https://platform.twitter.com/widgets.js";
      fjs.parentNode.insertBefore(js, fjs);

      t._e = [];
      t.ready = function (f) {
        t._e.push(f);
      };
      return t;
    })(document, "script", "twitter-wjs");*/
    /* eslint-enable */

    // sendinblue chat
    (function (d, w, c) {
      w.SibConversationsID = "638825f9ef36750931031bdd";
      w[c] =
        w[c] ||
        function () {
          (w[c].q = w[c].q || []).push(arguments);
        };
      var s = d.createElement("script");
      s.async = true;
      s.src =
        "https://conversations-widget.sendinblue.com/sib-conversations.js";
      if (d.head) d.head.appendChild(s);
    })(document, window, "SibConversations");
    /* end sendinblue chat script */
  },
  methods: {},
  props: {
    tweetWidth: {
      default: 230,
      type: Number,
    },
  },
};
</script>
<style scoped>
.bottom-fundraiser-form {
  padding: 5px 0 10px;
}
.bottom-fundraiser-form .heading-title h2 {
  margin-bottom: 5px;
}
.bottom-fundraiser-form form .form-control,
.bottom-fundraiser-form form .button {
  height: 35px !important;
  line-height: 10px;
}
</style>
