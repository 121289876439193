<template>
  <div>
    <section id="content">
      <div class="section nobg nomargin">
        <div class="container">
          <p></p>
          <h3>
            Our Commitment to Transparency, Fraud Prevention, and Excellence
          </h3>
          <p>
            <strong>Mission:</strong> “Our mission is to empower fundraising for
            Kenyans, by Kenyans. Fast. Cheap. Reliable.”
          </p>
          <strong>a. Our Commitment: </strong>
          <ul>
            <li>
              “We are committed to changing the way Kenyans and Africans
              fundraise and give reciprocally. Through the age-old practice of
              “Changa” we utilize technology to provide individuals, informal
              groups, organizations, and businesses a comprehensive solution to
              the challenges associated with the reality of fundraising today.
              With a 360 degree fundraising experience, our fundraising platform
              is like no other - we enable organizers to be more transparent,
              collect funds in a more efficient manner and make it easier for
              them to communicate and engage their contributors.”
            </li>
          </ul>

          <strong
            >b. Our Values: At M-Changa, our value system stems from the
            following:
          </strong>

          <ul>
            <li>
              Power of the Collective – We believe that success is a direct
              result of cooperation. Simply put, you can achieve more when you
              work in a group than when you work alone.
            </li>
            <li>
              <strong>Integrity – </strong>>We adhere to ethical principles of
              fundraising, whereby we are honest, transparent and accountable
              and promote these values as the key to successful fundraising. We
              use technology and human intelligence to be transparent about our
              company and your fundraisers.
            </li>
            <li>
              <strong>Excellence – </strong>We challenge ourselves to provide
              the best service to our customers through innovative solutions and
              a focus on customer service. This means we listen to you and
              regularly build new features and capacities in order to ensure our
              customers reach their goals.
            </li>
          </ul>
          <strong>c. Our Transparency: </strong>
          At M-Changa, we want to facilitate cost effective, reliable and
          transparent fundraisers. Transparency is the DNA of our mission. And
          because transparency is the DNA of our company, we walk the walk, and
          talk the talk. The following statistics shed light about our company:
          <ul>
            <li>Number of fundraisers:</li>
            <li>Number of supporters:</li>
            <li>Range of fundraiser totals: KES 250 to KES</li>
          </ul>
          Top 3 uses of our platform: Education fees, medical fees, and funerals
        </div>
      </div>
    </section>
     <Footer />
  </div>
</template>
<script>
import Breadcrumbs from "../components/Breadcrumbs.vue";
import Footer from '../components/Footer/index.vue';
export default {
  components: {
    Breadcrumbs,
    Footer
  },
  data() {
    return {};
  },
};
</script>
